import { DocumentSeriesAllocationService } from '@/modules/document-series-allocation/document-series-allocation-service';
import { AncpiService } from "@/shared/services/ancpi.service";
import Errors from '@/shared/error/errors';

// action types
export const DO_CREATE = 'create';
export const DO_UPLOAD = 'upload';
export const DO_UPDATE = 'update';
export const DO_SET_RECORD = 'doSetRecord';
export const DO_SET_UAT_IDS = 'doSetAvailableUatIds';
export const DO_SEARCH_PARCEL = "searchParcel";
export const DO_SET_REAL_ESTATES = "doSetRealEstates";

// mutation types
export const SET_RECORD = "setRecord";
export const SET_UAT_IDS = "setUatIds";
export const SET_PARCEL = "setParcel";
export const SET_REAL_ESTATES = "setRealEstates";

const getDefaultState = () => {
    return {
        record: null,
        parcels: [],
        availableUatIds: [],
        realEstateTarget: [],
    };
};

const state = getDefaultState();

const getters = {
    record: (state) => state.record,
    parcels: (state) => state.parcels,
    availableUatIds: (state) => state.availableUatIds,
    realEstateTarget: (state) => state.realEstateTarget,
};

const actions = {
    [DO_SET_RECORD](context, data) {
        context.commit(SET_RECORD, data);
    },
    [DO_SET_UAT_IDS](context, data) {
        context.commit(SET_UAT_IDS, data);
    },
    [DO_SET_REAL_ESTATES]({ commit }, payload) {
        commit(SET_REAL_ESTATES, payload);
    },
    [DO_CREATE](context, values) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'documentSeriesAllocation/create', { root: true });
            DocumentSeriesAllocationService.create(values)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'documentSeriesAllocation/create', { root: true });
                });
        });
    },
    [DO_UPLOAD](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'documentSeriesAllocation/upload', { root: true });
            const { uploadAccessToken, files } = payload;
            DocumentSeriesAllocationService.upload(uploadAccessToken, files)
                .then((data) => {
                    resolve(data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'documentSeriesAllocation/upload', { root: true });
                });
        });
    },
    [DO_UPDATE](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'documentSeriesAllocation/update', { root: true });
            DocumentSeriesAllocationService.update(payload)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'documentSeriesAllocation/update', { root: true });
                });
        });
    },
    async [DO_SEARCH_PARCEL](context, payload) {
        context.commit("shared/activateLoading", "documentSeriesAllocation/searchParcel", {
            root: true,
        });
        try {
            const parcel = await AncpiService.searchParcel(payload);
            if (parcel?.data?.area) {
                context.commit(SET_PARCEL, parcel.data);
            }

            return parcel;
        } catch (error) {
            Errors.handle(error);
        } finally {
            context.commit(
                "shared/deactivateLoading",
                "documentSeriesAllocation/searchParcel",
                { root: true }
            );
        }
    },
};

const mutations = {
    [SET_RECORD](state, record) {
        state.record = record;
    },
    [SET_UAT_IDS](state, uatIds) {
        const ids = uatIds.map(target => target.id);
        state.availableUatIds = ids;
    },
    [SET_PARCEL](state, data) {
        const parcel = state.parcels.find(
            (el) => el.topo_number === data.topo_number
        );
        if (!parcel) {
            state.parcels.push(data);
        }
    },
    [SET_REAL_ESTATES](state, realEstates) {
        state.realEstateTarget = realEstates;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
